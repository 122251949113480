import { isFile } from '@sim-admin-frontends/utils-shared';

import {
  MessageDataType,
  MessageType,
  TWhatsappFormValues,
  WhatsAppCsvRequest,
} from '../types/TWhatsapp';

const convertBase64 = async (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = (reader.result as string).split(',')[1]; // Remove the data URL prefix
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });

export const transformWhatsappValues = async (values: TWhatsappFormValues) => {
  const { type, files, recipient } = values;
  const valuesForBe: WhatsAppCsvRequest = {
    messageText: values.content || '',
    messageType: MessageType.TEXT,
  };
  const file = files?.[0];

  if (type?.value === MessageDataType.FILE && file && isFile(file)) {
    const csvData = await convertBase64(file);
    valuesForBe.csvData = csvData;
  } else if (type?.value === MessageDataType.NUMBER && recipient) {
    valuesForBe.recipient = recipient;
  } else {
    throw new Error('No data to send.');
  }

  return valuesForBe;
};

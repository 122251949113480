import { FC, useMemo } from 'react';
import {
  App,
  ProcessingStatus,
  useSaChangePaymentProcessingStatusMutation,
  useSaPaymentTransactionQuery,
} from '@sim-admin-frontends/data-access';
import {
  Error,
  getErrorMessage,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import PaymentEdit from './PaymentEdit';
import { getPaymentData } from '../../../utils/paymentsUtils';

const TOAST_SHARED_ID = 'paymentEdit';

type Props = {
  id: string;
  app?: App;
};

const PaymentEditContainer: FC<Props> = ({ id, app }) => {
  const { t } = useTranslation();
  const { data, isLoading, isError, refetch } = useSaPaymentTransactionQuery({ id, app });
  const { mutateAsync } = useSaChangePaymentProcessingStatusMutation();

  const paymentTransaction = useMemo(() => getPaymentData(data?.paymentTransaction), [data]);

  const changeProcessingStatus = (newProcessingStatus: ProcessingStatus) => async () => {
    try {
      loadingToast(t('payments.toastLoading'), {
        toastId: TOAST_SHARED_ID,
      });
      await mutateAsync({ id, processingStatus: newProcessingStatus });
      refetch();
      updateToast(TOAST_SHARED_ID, t('payments.toastSuccess'), TToastType.SUCCESS);
    } catch (err) {
      updateToast(TOAST_SHARED_ID, getErrorMessage(err), TToastType.ERROR);
    }
  };

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (isError || !paymentTransaction) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  return (
    <PaymentEdit
      paymentTransaction={paymentTransaction}
      changeProcessingStatus={changeProcessingStatus}
    />
  );
};

export default PaymentEditContainer;

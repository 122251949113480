import {
  Button,
  FormInput,
  FormSelect,
  FormTextarea,
  FormUpload,
  FormWrapper,
  TSelectItem,
} from '@sim-admin-frontends/ui-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import { isEmpty, isFile } from '@sim-admin-frontends/utils-shared';

import { MessageDataType, TWhatsappFormValues } from '../../../types/TWhatsapp';
import { ButtonsWrapper, Wrapper } from '../../common/Formstyles';

const SUPPORTED_FILE_TYPES = ['text/csv'];
const MAX_FILE_SIZE = 5242880;

const schema = (t: TFunction) =>
  Yup.object().shape({
    content: Yup.string().nullable().required(t('form.fieldRequired')),
    files: Yup.mixed()
      .nullable()
      .when('recipient', {
        is: (recipient?: string) => !!recipient,
        then: Yup.mixed().strip(),
        otherwise: Yup.mixed()
          .test('fileSize', t('whatsapp.edit.fileSize'), (files?: File[]) => {
            const file = files?.[0];
            return !!file && isFile(file) && file.size <= MAX_FILE_SIZE;
          })
          .test('fileType', t('whatsapp.edit.receiverValidation'), (files?: File[]) => {
            const file = files?.[0];
            return !!file && isFile(file) && SUPPORTED_FILE_TYPES.includes(file.type);
          }),
      }),
  });

type Props = {
  onSubmit: (values: TWhatsappFormValues) => Promise<void>;
};

const WhatsappEdit = ({ onSubmit }: Props) => {
  const { t } = useTranslation();

  const typeOptions = useMemo(
    () =>
      Object.values(MessageDataType).map((typeOption) => ({
        value: typeOption,
        label: typeOption,
      })),
    [],
  );

  const initialValues: TWhatsappFormValues = {
    type: typeOptions[0],
  };

  const methods = useForm<TWhatsappFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
    mode: 'all',
  });

  const { handleSubmit, register, formState, control, setValue } = methods;
  const { errors, isSubmitting } = formState;

  const onTypeChange = (typeOption: readonly TSelectItem[] | null) => {
    const typeValue = typeOption?.[0]?.value;
    if (typeValue === MessageDataType.FILE) {
      setValue('recipient', undefined, { shouldValidate: true });
    }
    if (typeValue === MessageDataType.NUMBER) {
      setValue('files', undefined, { shouldValidate: true });
    }
  };

  const [dataType] = useWatch({
    name: ['type'],
    control,
  });

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <FormSelect
            control={control}
            name="type"
            label={t('whatsapp.edit.type')}
            options={typeOptions}
            defaultValue={initialValues.type}
            onChange={onTypeChange}
            error={errors.files?.[0]}
          />
          {dataType?.value === MessageDataType.FILE && (
            <FormUpload
              control={control}
              name="files"
              dropzoneLabel={t('whatsapp.edit.file')}
              t={t}
              accept={SUPPORTED_FILE_TYPES}
            />
          )}
          {dataType?.value === MessageDataType.NUMBER && (
            <FormInput label={t('whatsapp.edit.number')} {...register('recipient')} />
          )}
          <FormTextarea
            label={t('whatsapp.edit.content')}
            {...register('content')}
            rows={3}
            error={errors.content}
          />
          <ButtonsWrapper>
            <Button
              size="smaller"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
            >
              {t('common.send')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default WhatsappEdit;
